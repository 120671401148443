import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Layout, Input, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import { API } from '../../AxiosWrapper';
import { filterData, filterObjectValues } from '../shared/utils';
import Sender from '../shared/Sender';
import { useColumns, searchableProps } from '../shared/useColumns';
import ConfigDropdown from '../shared/ConfigDropdown';
import DateRange from '../shared/DateRange';
import DeliveryTable from '../shared/DeliveryTable'
import { SearchIcon } from '../shared/icons'
import { useHandleApiError, useFormatMessage } from '../shared/hooks'
import ExportToExcel from '../shared/ExportToExcel'

const { Item } = Form;
const { Title } = Typography

const initialPageSize = 10;

const NotDelivered = ({ branchId, permissions, defaultSenderId, senders, allowedColumns }) => {
  const f = useFormatMessage()
  const handleApiError = useHandleApiError()
  const columns = useColumns(permissions, allowedColumns)

  const [deliveries, setDeliveries] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(dayjs());
  const [senderId, setSenderId] = useState(defaultSenderId);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [searchString, setSearchString] = useState('');

  const getDeliveries = () => {
    setLoading(true);
    let url = 'Delivery?Delivered=false';
    if (branchId) url += `&branchId=${branchId}`;

    API.GET(url)
      .then(response => setDeliveries(response.data
        .map(d => ({ ...d, senderOrderId: d.senderOrderID })).reverse())) //TODO fix camelCase on backend
      .catch(handleApiError)
      .finally(() => setLoading(false))
  }

  useEffect(getDeliveries, [branchId]) // eslint-disable-line react-hooks/exhaustive-deps

  const onDateChange = ({ startValue, endValue }) => {
    setStartDate(startValue);
    setEndDate(endValue);
  };

  const handleSearch = (e) => {
    setSearchString(e.target.value)
  }

  const filteredDeliveries = filterData({ senderId, showOnlyDelivered: false, startDate, endDate }, deliveries)
    .filter((obj) => filterObjectValues(obj, searchableProps, searchString));

  const [orderedColumns, setOrderedColumns] = useState(columns)

  const visibleColumns = orderedColumns.filter(col => col.visible)

  const seeLessButton = <Button type="link" onClick={() => setPageSize(initialPageSize)}>{f('Table.SeeLess')}</Button>;

  const seeAllButton = <Button type="link" onClick={() => setPageSize(filteredDeliveries.length)}>{f('Table.SeeAll')}</Button>;

  return (
    <Layout.Content>
      <Title level={2}><FormattedMessage id="Sidebar.NotDelivered" /></Title>
      <Form layout="vertical">
        <Row gutter={24}>
          <Col xs={24} lg={8}>
            <Item label={<span><FormattedMessage id="Input.Search" /></span>}>
              <Input
                className="standard-input"
                suffix={<SearchIcon />}
                onChange={handleSearch}
                placeholder={f('Input.SearchList')}
              />
            </Item>
          </Col>
          <Col xs={24} lg={8}>
            <DateRange
              onDateChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
              allowClear={true}
            />
          </Col>
          <Col xs={24} lg={8} className="justify-end-responsive">
            <Sender senders={senders} senderId={senderId} onSenderChange={id => setSenderId(id)} />
          </Col>
        </Row>
      </Form>

      <Row>
        <Col span={8}>
          <ExportToExcel dataSource={filteredDeliveries} columns={visibleColumns} />
        </Col>
        <Col span={8} style={{ textAlign: 'center' }}>
          {pageSize === filteredDeliveries.length && seeLessButton}
        </Col>
        <Col span={8} style={{ display: 'flex', justifyContent: 'end' }}>
          <ConfigDropdown
            tableName="NotDelivered"
            columns={orderedColumns}
            setColumns={setOrderedColumns}
            loading={loading}
          />
        </Col>
      </Row>

      <DeliveryTable
        columns={visibleColumns}
        dataSource={filteredDeliveries}
        loading={loading}
        pageSize={pageSize}
      />
      {(filteredDeliveries.length > 0) &&
        <Row className="see-all-row" justify="center">
          {pageSize === filteredDeliveries.length ? seeLessButton : seeAllButton}
        </Row>
      }
    </Layout.Content>
  );
}

export default NotDelivered;
